import {useState} from "react";
import {useAuth} from "./../../../utils/auth-context";
import {BASE_URL} from "./../../../api/api-сonfig";
import styles from "./outlook-avatar.module.css";

// filepond
import "filepond/dist/filepond.min.css";
import "filepond-plugin-file-poster/dist/filepond-plugin-file-poster.min.css";
import {FilePond, registerPlugin} from "react-filepond";
import FilePondPluginFilePoster from "filepond-plugin-file-poster";


registerPlugin(FilePondPluginFilePoster);

export default function OutlookAvatar({onFileUpload}: { onFileUpload: (url: string) => void; }) {
  const [files, setFiles] = useState([]);
  const {token} = useAuth();

  if (!token) {
    return <div>Loading...</div>;
  }

  return (
    <div className={styles.avatar}>
      <style>
        {`
          .filepond--credits {
              display: none;
          }
        `}
      </style>
      <FilePond
        instantUpload={false}
        files={files}
        //@ts-ignore
        onupdatefiles={setFiles}
        allowMultiple={false}
        filePosterMaxHeight={256}
        onprocessfile={(error, file) => {
          if (error) {
            console.error("Ошибка при загрузке файла:", error);
            return;
          }
          try {
            const response = JSON.parse(file.serverId);
            const fileUrl = response?.source_url;
            if (fileUrl) {
              onFileUpload(fileUrl);
            } else {
              console.error("В ответе не найден исходный URL-адрес:", response);
            }
          } catch (parseError) {
            console.error("Ошибка при разборе файла ServerID:", parseError);
          }
        }}
        server={{
          url: `${BASE_URL}/wp-json/wp/v2`,
          process: {
            url: "/media",
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            onload: (response) => {
              return response;
            },
            onerror: (response) => response.data,
          },
        }}
        name="file"
        labelIdle='Перетащите файл сюда или <span class="filepond--label-action">выберите</span>'
      />
    </div>
  );
}
