import { ChangeEvent, useState } from "react";
import { useImage } from "../../utils/image-context";
import styles from "./page-signature.module.css";
import OutlookSignatureAll from '../../components/outlook/outlook-signature-all/outlook-signature-all';
import Input from "../../ui-kit/input/input";
import OutlookAvatar from "../../components/outlook/outlook-avatar/outlook-avatar";

// Конфигурация для всех input полей
const inputsConfig = [
  { label: 'Имя', valueKey: 'inputName', placeholder: 'Имя' },
  { label: 'Фамилия', valueKey: 'inputFamily', placeholder: 'Фамилия' },
  { label: 'Отчество', valueKey: 'inputPatronymic', placeholder: 'Отчество' },
  { label: 'Телефон', valueKey: 'inputTelephoneSecond', placeholder: 'Телефон' },
  { label: 'Доб.', valueKey: 'inputTelephoneExt', placeholder: 'Телефон добавочный' },
  { label: 'Мобильный телефон', valueKey: 'inputTelephone', placeholder: 'Телефон' },
  { label: 'Должность', valueKey: 'inputPosition', placeholder: 'Должность' },
  { label: 'Адрес', valueKey: 'inputAddress', placeholder: 'Адрес', additionalClass: styles['label--column-2'] },
];

const PageSignature = () => {
  const [formData, setFormData] = useState({
    inputName: '',
    inputFamily: '',
    inputPatronymic: '',
    inputPosition: '',
    inputTelephone: '',
    inputTelephoneExt: '',
    inputTelephoneSecond: '',
    inputAddress: ''
  });

  const { imageUrl, setImageUrl } = useImage();
  const defaultImageUrl = "https://api.marketing.serconsrus.ru/wp-content/uploads/2024/08/avatar-2.png";
  const avatarUrl = imageUrl || defaultImageUrl;

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>, key: string) => {
    setFormData((prevData) => ({
      ...prevData,
      [key]: event.target.value
    }));
  };

  const handleFileUpload = (url: string) => {
    setImageUrl(url); // Обновляем URL изображения в контексте
  };

  return (
    <section className={styles.signature}>
      <div className="container">
        <div className={styles.signature__widget}>
          <div className={styles.signature__title}>
            <h1>Персональные данные</h1>
            <a className={styles.signature__manual} href="https://api.marketing.serconsrus.ru/wp-content/uploads/2024/09/ИНСТРУКЦИЯ-Подпись-сотрудника-в-Outlook.pdf" target="_blank" rel="noopener noreferrer">инструкция</a>
          </div>

          <div className={styles.signature__row}>

            <OutlookAvatar onFileUpload={handleFileUpload}/>

            <div className={styles.signature__data}>
              <div className={styles.signature__information}>
                {inputsConfig.map(({ label, valueKey, placeholder, additionalClass = '' }) => (
                  <Input
                    key={valueKey}
                    label={label}
                    value={formData[valueKey as keyof typeof formData]}
                    onChange={(e) => handleInputChange(e, valueKey)}
                    placeholder={placeholder}
                    additionalClass={additionalClass}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>

        <OutlookSignatureAll {...formData} avatarUrl={avatarUrl} />

      </div>
    </section>
  );
};

export default PageSignature;
